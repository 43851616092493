import 'jquery';
import 'lodash';
import 'underscore';
var $ = window.$ = window.jQuery = require('jquery');
import '../assets/plugins/datatables/js/jquery.datatables.min.js';
// import "../assets/js/github_calendar_heatmap/calendar_yearview_blocks.js";
// import "../assets/js/calendar_heatmap/calendar_heatmap.js";

import 'bootstrap';
import angular from 'angular';
import angularChartist from 'angular-chartist.js';
import 'angular-translate';

import 'angular-simple-logger';
import 'leaflet.label';
import 'leaflet.awesome-markers';
import 'Leaflet.vector-markers';
import 'leaflet-routing-machine';
// import 'leaflet-canvas-marker';
//import 'leaflet-extra-markers';
//import 'leaflet.fullscreen';


import 'ui-leaflet';



var L = window.L = require('leaflet');

 var ExtraMarkers = window.ExtraMarkers = require('leaflet-extra-markers');
 var canvasIconLayer = window.canvasIconLayer = L.canvasIconLayer = require('leaflet-canvas-marker');
 var routingMachine = window.routingMachine = L.routingMachine = require('leaflet-routing-machine');

window.$.fn.tableToCSV = function () {

    var clean_text = function (text) {
        return $.trim(text);
    };
   //test
    $(this).each(function () {
        var table = $(this);
        var caption = $(this).find('caption').text();
        var title = [];
        var rows = [];

        $(this).find('tr').each(function () {
            var data = [];
            $(this).find('th').each(function () {
                var text = clean_text($(this).text());
                title.push(text);
            });
            $(this).find('td').each(function () {
                var text = clean_text($(this).text());
                data.push(text);
            });
            data = data.join(",");
            rows.push(data);
        });
        title = title.join(",");
        rows = rows.join("\n");

        var csv = title + rows;
        var uri = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        var download_link = document.createElement('a');
        download_link.href = uri;
        var ts = new Date().getTime();
        if (caption == "") {
            download_link.download = ts + ".csv";
        } else {
            download_link.download = caption + "-" + ts + ".csv";
        }
        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);
    });

};


var _ = window._ = require('underscore');
const html2canvas = window.html2canvas = require('html2canvas')


require('jvectormap-next')($);




import * as Hammer from 'hammerjs';
window.Hammer = Hammer.default;


// angular modules
import constants from './constants';
import datatable_fr from './lang/datatable_fr';
import datatable_ar from './lang/datatable_ar';
import onConfig from './on_config';
import onRun from './on_run';
import 'ngstorage';
import 'angular-sanitize';
import 'angular-cookies';
import 'angular-animate';
import 'angular-touch';
import 'angular-ui-router';
import 'angular-flash-alert';
import 'angular-ui-bootstrap';
import 'ui-select';


import 'ngmap';
import 'angularjs-datepicker';
import 'angular-drag-and-drop-lists';
import 'angular-bind-html-compile';
import 'jquery-ui';
// import '../../node_modules/painterro/build/painterro.min.js';
import '../assets/js/calendar/fullcalendar.js';




/**
 * socket.io-client
 */
if (constants.USING_SOCKET_AND_REDIS) {
    window.io = require('socket.io-client');
    window.sock = io(constants.ADRESS_SERVER + ':' + constants.BROADCAST_PORT);
}


import './templates';
import './filters';
import './controllers';
import './services';
import './directives';
import './scripts';

window.pointInPolygon = require('point-in-polygon');

//import haversine from 'haversine-distance'
window.haversine = require('haversine-distance');
// create and bootstrap application

import * as turf from '@turf/turf'
window.turf = turf;

//window.geolib = require('geolib');

const requires = [
   // 'dx',
    'ui.router',
    'ngSanitize',
    'ngCookies',
    'ngMap',
    angularChartist,
    'pascalprecht.translate',
    'ngStorage',
    'templates',
    'app.filters',
    'app.controllers',
    'app.services',
    'app.directives',
    '720kb.datepicker',
    'ngFlash',
    'ui.select',
    'ngTouch',
    'ngAnimate',
    'ui.bootstrap',
    'dndLists',
    'angular-bind-html-compile',
];

// mount on window for testing
window.app = angular.module('app', requires);

angular.module('app').constant('AppSettings', constants);
angular.module('app').constant('datatable_fr', datatable_fr);
angular.module('app').constant('datatable_ar', datatable_ar);
angular.module('app').run(onRun);
angular.module('app').config(onConfig);



angular.bootstrap(document, ['app'], {
    strictDi: true
});
