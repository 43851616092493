function stock_depotController($translate, $timeout, $scope, WS, $window) {
    'ngInject';
    const vm = this;
    vm.stocks = {};
    vm.stock = {};
    vm.allsocietes = {};

    vm.stk_date_debut = moment().format('YYYY-MM-DD');
    vm.stk_date_fin = moment().format('YYYY-MM-DD');

    vm.stk_date_hier = moment(vm.stk_date_debut).subtract(1, "days").format('YYYY-MM-DD');
    vm.stk_date_last_inventaire = vm.stk_date_fin;

    vm.type='';



    vm.changeStkDateDebut = () => {
        vm.stk_date_hier = moment(vm.stk_date_debut).subtract(1, "days").format('YYYY-MM-DD');
    }

    vm.changeStkDateFin = () => {
        vm.stk_date_last_inventaire = vm.stk_date_fin;
    }


    vm.getStocksAndInventaires = () => {
        WS.getData('stock_depot/getStocksAndInventaires', {
            stk_date_hier : vm.stk_date_hier,
            stk_date_debut : vm.stk_date_debut,
            stk_date_fin : vm.stk_date_fin,
            stk_date_last_inventaire : vm.stk_date_last_inventaire,
            soussociete_code : vm.soussociete_code

        }).then(
            function (response) {
                vm.inventaire_hier = response.data.inventaire_hier;

               // vm.first_inventaire = response.data.first_inventaire;

                vm.last_inventaire = response.data.last_inventaire;

                vm.list_chargements = response.data.list_chargements;

                vm.total_chargements = response.data.total_chargements;

                vm.list_achats = response.data.list_achat;

                $scope.$apply();
                $("document").ready(function () {
                    $("#scroll1 div").width($(".table_scroll").width());
                    $("#scroll1").on("scroll", function () {
                        console.log('2', $(this).scrollLeft())
                        $("#scroll2").scrollLeft($(this).scrollLeft());
                    });
                    $("#scroll2").on("scroll", function () {
                        $("#scroll1").scrollLeft($(this).scrollLeft());
                    });
                });

            },
            function (error) {
                console.log(error);
            });
    }

    vm.selectSoussocietes = function () {
        WS.get('soussocietes/selectSoussocietes').then(
            function (response) {
                vm.soussocietes = response.data;
                _.each(vm.soussocietes , element => {
                    vm.allsocietes[element.code] = element.nom;
                })

                if(vm.soussocietes.length == 1){
                    vm.soussociete_code = vm.soussocietes[0].code;
                    vm.getStockDepot();
                }

                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };

    vm.selectSoussocietesTransfert = function () {
        WS.get('soussocietes/selectSoussocietes').then(
            function (response) {
                vm.soussocietes = response.data;
                _.each(vm.soussocietes , element => {
                    vm.allsocietes[element.code] = element.nom;
                })

                if(vm.soussocietes.length == 1){
                    vm.soussociete_code = vm.soussocietes[0].code;
                    vm.stock.first_soussociete_code = vm.soussocietes[0].code;
                    vm.getStockDepotTransfer();
                }

                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };

    vm.getStockDepot = function () {
        WS.getData('stock_depot/getStockDepot', {soussociete_code : vm.soussociete_code, fournisseur_code : vm.fournisseur_code})
            .then(function (response) {

                vm.stocks_init = response.data.stocks;
                vm.produits = response.data.produits;
                vm.total_vente_ca = 0;
                vm.total_achat_ca = 0;
                _.each(vm.stocks_init, (element, index) => {
                    if(vm.produits[index]){
                        vm.total_vente_ca += element.quantite * vm.produits[index].prix_ttc;
                        vm.total_achat_ca += element.quantite * vm.produits[index].prix_achat_ttc;
                        vm.produits[index].quantite = element.quantite;
                        vm.produits[index].retour = element.retour;
                        vm.produits[index].ordre = element.ordre;
                        vm.produits[index].dlc = element.dlc;
                        vm.produits[index].casse = element.casse;
                        vm.produits[index].securite = element.securite;
                        vm.produits[index].reservation = element.reservation;
                        vm.produits[index].soussociete_code = vm.soussociete_code;
                        vm.produits[index].soussociete = vm.allsocietes[vm.soussociete_code];
                    }
                })
               vm.stock_depots=[];
               vm.stocks = {};
                _.each(vm.produits, stock => {
                    vm.stock_depots.push(stock);

                    vm.stocks[stock.id] = {};
                    vm.stocks[stock.id]['produit_code']= stock.produit_code;
                    vm.stocks[stock.id]['ordre']= stock.ordre;
                    vm.stocks[stock.id]['id']=stock.id;
                    vm.stocks[stock.id]['quantite']=stock.quantite ? stock.quantite : 0;
                    vm.stocks[stock.id]['retour']=stock.retour ? stock.retour : 0;
                    vm.stocks[stock.id]['colisage']=stock.colisage;
                    vm.stocks[stock.id]['dlc']=stock.dlc ? stock.dlc : 0;
                    vm.stocks[stock.id]['casse']=stock.casse ? stock.casse : 0;
                    vm.stocks[stock.id]['reservation']=stock.reservation ? stock.reservation : 0;
                    vm.stocks[stock.id]['securite']=stock.securite ? stock.securite : 0;
                    vm.stocks[stock.id]['prix_ttc']=stock.prix_ttc;
                    vm.stocks[stock.id]['prix_achat_ttc']=stock.prix_achat_ttc;
                    vm.stocks[stock.id]['soussociete_code']=vm.soussociete_code;
                    vm.stocks[stock.id]['soussociete']=vm.allsocietes[vm.soussociete_code];

                })


                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getStockDepotTransfer = function () {
        WS.getData('stock_depot/getStockDepot', {soussociete_code : vm.stock.first_soussociete_code, fournisseur_code : vm.fournisseur_code})
            .then(function (response) {

                vm.stocks_init_trans = response.data.stocks;
                vm.produits = response.data.produits;
                vm.total_vente_ca = 0;
                vm.total_achat_ca = 0;
                _.each(vm.stocks_init_trans, (element, index) => {
                    if(vm.produits[index]){
                        vm.total_vente_ca += element.quantite * vm.produits[index].prix_ttc;
                        vm.total_achat_ca += element.quantite * vm.produits[index].prix_achat_ttc;
                        vm.produits[index].quantite = element.quantite;
                        vm.produits[index].retour = element.retour;
                        vm.produits[index].dlc = element.dlc;
                        vm.produits[index].casse = element.casse;
                        vm.produits[index].securite = element.securite;
                        vm.produits[index].reservation = element.reservation;
                        vm.produits[index].soussociete_code = vm.stock.first_soussociete_code;
                        vm.produits[index].soussociete = vm.allsocietes[vm.stock.first_soussociete_code];
                    }
                })
               vm.stock_depots=[];
               vm.stocks = {};
                _.each(vm.produits, stock => {
                    vm.stock_depots.push(stock);
                  //  console.log(stock)
                    vm.stocks[stock.id] = {};
                    vm.stocks[stock.id]['produit_code']=stock.produit_code;
                    vm.stocks[stock.id]['id']=stock.id;
                    vm.stocks[stock.id]['quantite']=stock.quantite ? stock.quantite : 0;
                    vm.stocks[stock.id]['retour']=stock.retour ? stock.retour : 0;
                    vm.stocks[stock.id]['colisage']=stock.colisage;
                    vm.stocks[stock.id]['dlc']=stock.dlc ? stock.dlc : 0;
                    vm.stocks[stock.id]['casse']=stock.casse ? stock.casse : 0;
                    vm.stocks[stock.id]['reservation']=stock.reservation ? stock.reservation : 0;
                    vm.stocks[stock.id]['securite']=stock.securite ? stock.securite : 0;
                    vm.stocks[stock.id]['prix_ttc']=stock.prix_ttc;
                    vm.stocks[stock.id]['prix_achat_ttc']=stock.prix_achat_ttc
                    vm.stocks[stock.id]['soussociete_code']=vm.stock.first_soussociete_code;
                    vm.stocks[stock.id]['soussociete']=vm.allsocietes[vm.stock.first_soussociete_code];
                })
                console.log('vm.stocks', vm.stocks)


                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.getSecondStockDepotTransfer = function () {
       // console.log(vm.stocks)
        _.each(vm.stocks , (element,index)=> {
           // console.log(element,index);
           vm.stocks[index]['quantite_2']= 0;
           vm.stocks[index]['retour_2']= 0;
           vm.stocks[index]['dlc_2']= 0;
            vm.stocks[index]['casse_2']= 0;
            vm.stocks[index]['reservation_2']= 0;
            vm.stocks[index]['securite_2']= 0;
        })
        WS.getData('stock_depot/getStockDepot', {soussociete_code : vm.stock.second_soussociete_code, fournisseur_code : vm.fournisseur_code})
            .then(function (response) {

                var stock_res = response.data.stocks;
                vm.produits = response.data.produits;
                vm.total_vente_ca = 0;
                vm.total_achat_ca = 0;
                console.log('stocks', vm.stocks);
                console.log('stock_res',stock_res);
                _.each(stock_res, (element, index) => {
                    if(vm.produits[index]){
                        // vm.total_vente_ca += element.quantite * vm.produits[index].prix_ttc;
                        // vm.total_achat_ca += element.quantite * vm.produits[index].prix_achat_ttc;
                        vm.produits[index].quantite_2 = element.quantite;
                        vm.produits[index].retour_2 = element.retour;
                        vm.produits[index].dlc_2 = element.dlc;
                        vm.produits[index].casse_2 = element.casse;
                        vm.produits[index].securite_2 = element.securite;
                        vm.produits[index].reservation_2 = element.reservation;
                        // vm.produits[index].soussociete_code_2 = vm.stock.second_soussociete_code;
                        // vm.produits[index].soussociete_2 = vm.allsocietes[vm.stock.second_soussociete_code];


                    }
                    console.log(element.produit_id, vm.stocks[element.produit_id])
                    if(vm.stocks[element.produit_id]){

                        vm.stocks[element.produit_id]['quantite_2']=element.quantite ? element.quantite : 0;
                        vm.stocks[element.produit_id]['retour_2']=element.retour ? element.retour : 0;
                        console.log(element.produit_id, 'quantite_2',vm.stocks[element.produit_id]['quantite_2'] )
                        vm.stocks[element.produit_id]['dlc_2']=element.dlc ? element.dlc : 0;
                        vm.stocks[element.produit_id]['casse_2']=element.casse ? element.casse : 0;
                        vm.stocks[element.produit_id]['reservation_2']=element.reservation ? element.reservation : 0;
                        vm.stocks[element.produit_id]['securite_2']=element.securite ? element.securite : 0;
                    }
                })
              // vm.stock_depots=[];
               //vm.stocks = {};
                // _.each(vm.produits, stock => {
                //    // vm.stock_depots.push(stock);
                //     console.log(stock)

                //     if(vm.stocks[stock.id]){
                //         vm.stocks[stock.id]['quantite_2']=stock.quantite_2 ? stock.quantite : 0;
                //         console.log(stock.id, 'quantite_2',vm.stocks[stock.id]['quantite_2'] )
                //         vm.stocks[stock.id]['dlc_2']=stock.dlc_2 ? stock.dlc_2 : 0;
                //         vm.stocks[stock.id]['casse_2']=stock.casse_2 ? stock.casse_2 : 0;
                //         vm.stocks[stock.id]['reservation_2']=stock.reservation_2 ? stock.reservation_2 : 0;
                //         vm.stocks[stock.id]['securite_2']=stock.securite_2 ? stock.securite_2 : 0;
                //     }
                // })

                //console.log(vm.stocks);
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.sendTransfert = () => {
        console.log(vm.stocks);
        console.log(vm.stock)

        var transfert = []
        _.each(vm.stocks, element => {
            if(element.quantite_transfert> 0 && element.quantite_transfert != undefined){
                transfert.push({
                    produit_code : element.produit_code,
                    quantite : element.quantite,
                    quantite_2: element.quantite_2,
                    quantite_transfert : element.quantite_transfert

                })
            }
        })

        var data = {
            first_soussociete_code: vm.stock.first_soussociete_code,
            second_soussociete_code: vm.stock.second_soussociete_code,
            stocks : transfert
        }
        console.log(data)
        WS.post('stock_depot/sendTransfert', data)
            .then(function (response) {
                if (response.status == 200) {

                    swal(
                        $translate.instant('Succés'),
                        $translate.instant("Action effectuée avec succés"),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    }).catch(function () {
                        $window.location.reload();
                    });;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.correctionStockDepot = function () {
        // var data = [];
        vm.latitude = null;
        vm.longitude = null;
        console.log(vm.stocks);
        if (vm.cause && vm.soussociete_code) {
            navigator.geolocation.getCurrentPosition(function (position) {
                vm.latitude = position.coords.latitude;
                vm.longitude = position.coords.longitude;
                console.log("s", vm.latitude, vm.longitude);

                WS.post('stock_depot/correctionStockDepot',
                    {
                        stocks: vm.stocks,
                        latitude: vm.latitude,
                        longitude: vm.longitude,
                        cause: vm.cause,
                        soussociete_code: vm.soussociete_code
                    }
                ).then(function (response) {
                    if (response.data.success == true) {
                        swal(
                            'Succès',
                            'Correction effectué avec succé',
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        }).catch(function () {
                            $window.location.reload();
                        });
                    } else {
                        swal(
                            'Attention',
                            'Erreur de traitement des données',
                            'warning'
                        );
                    }
                    $scope.$apply();
                })
                    .then(null, function (error) {
                        console.log(error);
                    });

            });
            console.log('data', vm.stocks, vm.latitude, vm.longitude, vm.cause);

        } else {
            $('#cause').css('border', '2px solid red!important');
            swal(
                'Attention',
                'cause et distributeur obligatoires',
                'warning'
            );
        }
    };

    vm.verifyPassword = () => {
        WS.post('stock_depot/verifyPassword', { password: vm.current_password })
            .then(function (response) {
                if (response.status == 200) {
                    vm.verified_user = response.data.verified;
                    if (vm.verified_user) {
                        vm.edit = true;

                        $('#verification_modal').hide();
                        $('.modal-backdrop').remove();
                    } else {
                        swal(
                            $translate.instant('Oups!'),
                            $translate.instant("Vous n'êtes pas autorisé"),
                            'warning'
                        );
                    }
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.historiqueStockDepot = function (page = 1, per_page = 100) {
        WS.getData('stock_depot/historiqueStockDepot',
            {
                per_page, page,
                date_debut: vm.date_debut,
                date_fin: vm.date_fin,
                user_code: vm.user_code,
                produit_code: vm.produit_code,
                type : vm.type,
                code : vm.code
            }
        ).then(function (response) {
            vm.historique_stock = response.data.data;
            vm.current_page = response.data.current_page;
            vm.previous = vm.current_page - 1;
            vm.next = vm.current_page + 1;
            vm.from = response.data.form;
            vm.last_page = response.data.last_page;
            vm.next_page_url = response.data.next_page_url;
            vm.per_page = Number(response.data.per_page);
            vm.prev_page_url = response.data.prev_page_url;
            vm.to = response.data.to;
            vm.total = response.data.total;
            vm.paginate = [];
            for (var i = 1; i <= vm.last_page; i++) {
                vm.paginate.push(i);
            }
            $scope.$apply();
        })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.checkpaginate = function(current, page, lastpage) {
        if (page > current + 3 || page < current - 3) {
            return false;
        } else {
            return true;
        }
    }

    vm.selectProduits = function () {
        WS.get('produits/selectProduit').then(
            function (response) {
                vm.selectproduits = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    }
    vm.selectUsers = function () {
        WS.get('users/selectUsers', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectFournisseurs = function () {
        WS.get("fournisseurs/selectFournisseursWithCode")
          .then(function (response) {
            if (response.status == 200) {
              vm.select_frs = response.data;
              $scope.$apply();
            } else {
            }
          })
          .then(null, function (error) {
            console.log(error);
          });
      };


    vm.selectUsersFRomSTK = function () {
        WS.get('stock_depot/selectUsersFRomSTK')
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };



    vm.sendTransfertEmplacement = () => {
        console.log("sendTransfertEmplacement");
        console.log(vm.stocks);
        console.log(vm.stock)
        if(vm.stock.type_transfert && vm.cause){
            var transfert = []
            var error=0;
            _.each(vm.stocks, element => {
                if(element.quantite_transfert> 0 && element.quantite_transfert != undefined){

                   if(vm.stock.type_transfert=="quantite_retour") {
                        if(element.quantite>= element.quantite_transfert){
                            transfert.push({
                                produit_code : element.produit_code,
                                quantite : element.quantite,
                                retour: element.retour,
                                quantite_transfert : element.quantite_transfert

                            })
                        }else{
                            error++;
                        }
                   }



                   if(vm.stock.type_transfert=="retour_quantite") {
                        if(element.retour>= element.quantite_transfert){
                            transfert.push({
                                produit_code : element.produit_code,
                                quantite : element.quantite,
                                retour: element.retour,
                                quantite_transfert : element.quantite_transfert

                            })
                        }else{
                            error++;
                        }
                   }
                }
            })

            if(error==0){
                var data = {
                    soussociete_code: vm.stock.first_soussociete_code,
                    fournisseur_code: vm.stock.fournisseur_code,
                    type_transfert: vm.stock.type_transfert,
                    cause: vm.cause,
                    stocks : transfert
                }
                //console.log(data)
                WS.post('stock_depot/sendTransfertEmplacement', data)
                    .then(function (response) {
                        if (response.status == 200) {

                            swal(
                                $translate.instant('Succés'),
                                $translate.instant("Action effectuée avec succés"),
                                'success'
                            ).then(function () {
                                $window.location.reload();
                            }).catch(function () {
                                $window.location.reload();
                            });;

                            $scope.$apply();
                        } else { }
                    })
                    .then(null, function (error) {
                        console.log(error);
                    });
            }else{
                swal(
                    $translate.instant('Oupss!'),
                    $translate.instant("Veuillez bien verifier les quantités"),
                    'warning'
                )
            }

        }else{
            swal(
                $translate.instant('Oupss!'),
                $translate.instant("Veuillez bien remplir le type de transfert et la cause"),
                'warning'
            )
        }

    }


}
export default {
    name: 'stock_depotController',
    fn: stock_depotController
};
